import React from "react";
import pic from "../../../src/assets/logo.png";
import "./style.css";
import { Box, Container } from "@mui/system";
import { Button, Grid, Typography } from "@mui/material";
import Logo from "components/atomic/Logo";
import { Link, useNavigate } from "react-router-dom";

const ErrorPage = () => {

  const navigate = useNavigate()
  return (
    <Container sx={{ height: '100vh', backgroundColor: 'white' }}>
      <Grid container sx={{ height: '100vh', "& .MuiGrid-root": { textAlign: 'center' } }}>
        <Grid item xs={12} sx={{ "p": { color: '#1c39bb' }, display: 'flex', flexDirection: 'column', alignContent: 'start' }}>
        <Box component={Link} sx={{marginBlock:2, display: 'flex', alignItems: 'center' }} to="/">
          <Logo text />
        </Box>
          <Typography sx={{ fontSize: '180px', fontWeight: 700, lineHeight: 1.1 }}>
            404
          </Typography>

          <Typography sx={{ fontSize: '50px', fontWeight: 700 }}>
            Sorry!
          </Typography>

          <Typography sx={{ fontSize: '35px', marginBlock: '8px' }}>
            The page you are looking for was not found
          </Typography>

          <Box sx={{ marginBlock: '24px' }}>
            <Button onClick={() => navigate("/")} variant="outlined" sx={{ borderRadius: '16px', paddingInline: '24px', fontSize: '16px', fontWeight: '700' }}>
              Back To Home
            </Button>
          </Box>

          <Box>
            <Typography sx={{ fontSize: '12px', fontWeight: 700 }}>
              In the meantime, reach us at:
            </Typography>

            <Typography sx={{ fontSize: '12px' }}>
              info@baramen.com
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ErrorPage;
